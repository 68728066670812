import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import FranchisePreInvoiceVisualizaComponent from '@/components/FranchisePreInvoiceVisualizaComponent.vue';
let LivroSolicitadoDiretoFranchiseButton = class LivroSolicitadoDiretoFranchiseButton extends Vue {
    constructor() {
        super(...arguments);
        this.dialogFranchisePreInvoiceVisualiza = false;
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    onConfirm() {
        this.$emit('on-confirm');
        this.dialogFranchisePreInvoiceVisualiza = false;
    }
};
__decorate([
    Prop({ type: Number, default: null })
], LivroSolicitadoDiretoFranchiseButton.prototype, "propPreInvoiceId", void 0);
__decorate([
    Prop({ type: Number, default: null })
], LivroSolicitadoDiretoFranchiseButton.prototype, "propPreInvoiceStatusId", void 0);
LivroSolicitadoDiretoFranchiseButton = __decorate([
    Component({
        components: {
            FranchisePreInvoiceVisualizaComponent,
        },
    })
], LivroSolicitadoDiretoFranchiseButton);
export default LivroSolicitadoDiretoFranchiseButton;
